@font-face {
    font-family: "Recife Display";
    src: url("../fonts/RecifeDisplay/RecifeDisplayLight.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "VictorSerif";
    src: url("../fonts/VictorSerif-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "After Screen";
    src: url("../fonts/aften_screen.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Ogg Roman";
    src: url("../fonts/OggRoman.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Brown";
    src: url("../fonts/Brown-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Calibre";
    src: url("../fonts/Calibre/Calibre-Light.otf") format("opentype");
    font-weight: 00;
    font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul {
    list-style: none;
}

li,
button {
    padding: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

a:focus,
button:focus {
    outline: none;
}

button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

input,
textarea,
select {
    outline: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

small {
    font-size: 90%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    box-sizing: border-box;
}

button {
    font-family: inherit;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    color: #ffffff;
}

html,
body {
    height: 100%;
}

body {
    font-family: "Ogg Roman", serif;
    font-family: "VictorSerif", serif;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Ogg Roman", serif;
    font-family: "VictorSerif";
}

h1 {
    font-weight: 500;
    font-size: 37px;
    line-height: 1.4;
    text-align: center;
}

h2 {
    font-size: 26px;
}

p {
    font-size: inherit;
    line-height: 1.5;
    font-family: "Calibre", sans-serif;
}

small {
    font-size: 15px;
    font-family: "Calibre", sans-serif;
}

i {
    font-style: italic;
}

li {
    list-style: none;
}

input {
    background: transparent;
    border: none;
    font-size: 100%;
    font-family: inherit;
}

::placeholder {
    color: rgba(255, 255, 255, 0.233);
}

::-moz-placeholder {
    color: rgba(255, 255, 255, 0.233);
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.233);
}

.app-name {
    font-family: "Brown", sans-serif;
    text-transform: uppercase;
    border: 1px solid #ffffff;
    padding: 20px;
    font-size: 15px;
    letter-spacing: 3px;
    min-width: 10px;
    min-height: 60px;
}

.typed-cursor,
.typed-cursor.typed-cursor--blink {
    display: none !important;
}

.mobile-menu {
    display: none;
    width: 45px;
    cursor: pointer;
}

// .mobile-menu.left {
//     transform: rotate(180deg);
// }
.header-inner {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    margin-top: 40px;
    left: 0;
    right: 0;
    padding: 0 5%;
    z-index: 99999999;
}

.submit-story {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 150px;
}

.submit-story.green {
    background: #376055 !important;
}

.submit-story.blue {
    background: #497c88 !important;
}

.story-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90%;
    margin: auto;
}

form {
    width: 100%;
}

/* home */

.home {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: linear-gradient(#0c2533, #19364a);
    background: linear-gradient(#0c2533, #4e4f50);
    background: #212325;
    transition: all 300ms ease-in-out;
    &.green {
        background-color: #376055;
    }
    &.blue {
        background-color: #497c88;
    }
}

.home::before {
    content: "";
    z-index: 1;
    position: absolute;
    left: calc(50% - 0.5px);
    top: 200px;
    left: calc(50% - 0.5px);
    width: 1px;
    height: calc(100% - 350px);
    height: 100%;
    width: 1px;
    background-color: #fff;
    opacity: 0.18;
    transition: all 500ms ease-in-out;
}

.home.no-divider::before {
    top: 0;
    height: 0;
}

.home-inner {
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
    flex-direction: column;
}

.intro-content {
    position: relative;
    width: 100%;
}

.loading-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    place-items: center;
    background: #212325;
}

.loading-text {
    font-size: 26px;
    text-align: center;
    margin: 30px;
}

.slideshow-gif {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 380px;
    border-radius: 3px;
    z-index: -9;
    opacity: 0.02;
    animation: slideshow 3s infinite;
}

@keyframes slideshow {
    from {
        opacity: 0.02;
    }
    to {
        opacity: 0.2;
    }
}

@-webkit-keyframes slideshow {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.15;
    }
}

.home-intro {
    span {
        color: #e08d5d;
    }
}

@media screen and (min-width: 640px) {
    .home-intro {
        span {
            background: linear-gradient(90deg, #e08d5d 30%, #48946e 30%, #5aa9b3 40%);
            background-size: auto auto;
            background-clip: border-box;
            background-size: 50% auto;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: textclip 9s linear infinite;
        }
    }
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

/* slideshow */

.slideshow {
    z-index: 9999;
}

// .slideshow-gif {
//     // background: url("../assets/images/slideshow.gif") no-repeat;
//     background-size: cover;
//     background-position: center;
//     width: 300px;
//     height: 300px;
// }
.slideshow-slide {
    display: block;
    background-size: cover;
    background-position: center;
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 480px) {
    .slideshow-slide {
        width: 230px;
        height: 230px;
    }
}

@media screen and (max-width: 320px) {
    .slideshow-slide {
        width: 190px;
        height: 190px;
    }
}

/* about */

.about-project {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    margin: auto;
    background: #212325;
    min-height: 100vh;
    z-index: 9999999999;
}

.about-project-inner {
    text-align: center;
    max-width: 590px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.about-project-text {
    margin-top: 25px;
}

.close-about {
    font-size: 26px;
    cursor: pointer;
    margin-top: 5rem;
}

/* stories */

.stories {
    height: 100vh;
    background: #212325;
    background-attachment: fixed;
    position: relative;
    span {
        display: block;
    }
}

@keyframes fadein {
    0% {
        opacity: 0.3;
        transform: scale(1);
    }
    30% {
        opacity: 0.5;
        transform: scale(1.5);
    }
    50% {
        opacity: 1;
        transform: scale(2);
    }
    70% {
        opacity: 0.5;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes bgAnimation {
    0% {
        opacity: 0.3;
    }
    33.333% {
        opacity: 0.6;
    }
    60% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.2;
    }
}

.responses-list {
    padding-top: 150px;
    overflow: scroll;
    position: relative;
    z-index: 999999;
}

.responses-map {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: auto;
    width: 50%;
    opacity: 0.3;
    z-index: 1;
}

.responses-toggle {
    text-align: center;
    position: fixed;
    text-align: center;
    bottom: 30px;
    left: 0;
    right: 0;
}

.responses-toggle img {
    width: 35px;
    height: 35px;
    transition: all 500ms ease-in-out;
}

.responses-inner {
    z-index: 999999;
    height: 70vh;
    overflow: scroll;
    margin: auto;
    text-align: center;
}

@media screen and (min-width: 640px) {
    // Chrome, Safari and Opera
    .responses-inner::-webkit-scrollbar {
        display: none;
    }
    // IE, Edge and Firefox
    .responses-inner {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.single-response {
    // font-family: "Calibre", sans-serif;
    font-size: 22px;
    line-height: 1.6;
    padding: 23px 0;
}

.single-response.white {
    opacity: 0.4;
}

.response-owner {
    font-family: "Calibre", sans-serif;
    font-style: italic;
    color: #c2c2c2;
}

.app-menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    margin: auto;
    z-index: 9999999;
    ul {
        font-family: "Brown", sans-serif;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.for-mobile {
    display: none;
}

.menu-link {
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 3px;
    min-width: 260px;
    margin: auto;
    font-size: 14px;
    opacity: 0.4;
    transition: all 300ms ease-in-out;
    &:hover {
        opacity: 1;
    }
}

.form-body {
    transition: all 500ms ease-in-out;
}

.body.has-phrases .form-body {
    opacity: 0.1;
}

.form-group {
    position: relative;
}

.form-group-block {
    display: inline-block;
}

.form-group-block label {
    display: block;
    margin-bottom: 10px;
}

.form-group-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-end;
    max-width: 80%;
    margin: auto;
}

.form-error {
    color: #ff9191;
}

.form-input {
    display: inline-block;
    font-size: 20px;
    padding: 10px 0 5px;
    border-radius: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.301);
    margin-right: 40px;
    min-width: 220px;
    transition: all 300ms ease-in-out;
    &:focus {
        border-bottom: 1px solid #ffffff;
    }
}

.saving span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    animation-delay: 0.2s;
}

.saving span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0%,
    100% {
        opacity: 0.1;
    }
    20% {
        opacity: 1;
    }
}

.success-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 600px;
    margin: auto;
    transition: all 300ms ease-in-out;
    .inactive-overlay {
        background: radial-gradient(black, rgba(0, 0, 0, 0.616), transparent);
    }
}

.success-arrow {
    width: 40px;
    transition: 300ms ease-in-out;
    vertical-align: middle;
    margin-left: 20px;
}

.success-cta {
    margin-top: 30px;
    h1 {
        cursor: pointer;
        &:first-of-type {
            padding-bottom: 15px;
        }
    }
}

.success-headings {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}

.share-channels {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin: 0 20px;
        width: 40px;
    }
}

.main-label {
    position: relative;
    text-align: left;
    font-size: 55px;
    margin: 80px 0 40px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.1px;
}

.main-label-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.phrase-switch {
    cursor: pointer;
    font-size: 20px;
}

@keyframes arrowAnimation {
    0% {
        transform: rotate(180deg) translateY(10px);
    }
    50% {
        transform: rotate(180deg) translateY(0);
    }
    100% {
        transform: rotate(180deg) translateY(10px);
    }
}

.main-input {
    width: 100%;
    text-align: left;
    font-size: 45px;
    padding: 10px 250px 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.431);
    border-radius: 0;
    &:focus {
        border-bottom: 1px solid #ffffff;
    }
    &::placeholder {
        color: rgba(255, 255, 255, 0.233);
    }
}

.phrase-dropdown {
    position: absolute;
    z-index: 99999999;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 50%;
    height: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.phrase-dropdown li:first-of-type {
    display: none;
}

.phrase-dropdown li:nth-of-type(2n) {
    display: none;
}

.phrase-dropdown li:nth-of-type(3n) {
    display: none;
}

.phrase-dropdown li:last-of-type {
    display: none;
}

.inactive-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: linear-gradient(0deg, transparent, transparent);
    transition: 500ms background ease-in-out;
}

body.has-phrases {
    .phrase-dropdown {
        height: max-content;
        li {
            display: block;
        }
    }
    .phrase-dropdown li:first-of-type {
        animation: fadein 1s backwards;
    }
    .phrase-dropdown li:nth-of-type(2n) {
        animation: fadein 2s backwards;
    }
    .phrase-dropdown li:nth-of-type(3n) {
        animation: fadein 3s backwards;
    }
    .phrase-dropdown li:last-of-type {
        animation: fadein 5s backwards;
    }
    .inactive-overlay {
        background-color: rgba(0, 0, 0, 0.911);
        // background: rgb(0, 0, 0);
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0.85) 100%);
        z-index: 99;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.phrase-toggle {
    display: none;
    position: fixed;
    right: 21px;
    top: 26px;
    z-index: 9999999943435645;
    transition: all 500ms ease-in-out;
}

body.has-phrases .phrase-toggle {
    transform: rotate(45deg);
}

.phrase-item {
    font-family: "Ogg Roman", serif;
    font-family: "VictorSerif", serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 2;
    color: #979797;
    cursor: pointer;
    transition: all 500ms ease;
}

.phrase-item:hover {
    transform: translateX(10px);
    color: #ffffff;
}

.success-action-button {
    font-family: "Ogg Roman";
    font-weight: 500;
    font-size: 37px;
    margin: 0 0 20px;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: translateX(10px);
    }
}

/* buttons */

.button {
    cursor: pointer;
    font-weight: 500;
    font-size: 35px;
    color: #ffffff;
}

.button img {
    width: 27px;
    vertical-align: middle;
    margin-left: 20px;
    margin-top: -1px;
}

.submit-button {
    position: absolute;
    right: 0;
    bottom: 15px;
    opacity: 0.3;
    transition: all 300ms ease;
    &:disabled {
        opacity: 0.3 !important;
        cursor: not-allowed;
    }
}

.submit-button.show {
    opacity: 1;
}

/* .submit-button.for-mobile {
    display: none;
    position: relative;
    bottom: 0;
    margin-left: 0;
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.13);
    padding: 20px;
    border-radius: 2px;
    text-align: center;
    width: 100%;
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
} */

.form-footer {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    padding-top: 15px;
}

.form-clause {
    font-size: 18px;
    display: block;
    opacity: 0;
    font-style: italic;
    transition: all 500ms ease;
}

body.has-clause {
    .form-clause,
    .submit-button {
        opacity: 1;
    }
}

.nav-arrow {
    cursor: pointer;
    transform: rotate(180deg);
    width: 25px;
}

.mobile-menu-icon {
    display: none;
}

@media screen and (max-width: 1440px) {
    .main-label {
        font-size: 50px;
    }
}

@media screen and (max-device-width: 1366px) and (orientation: landscape) {
    .submit-story {
        padding: 0 100px;
    }
}

@media screen and (max-device-width: 1366px) and (orientation: portrait) {
    /* .footer {
        bottom: 40px;
    } */
    /* .footer {
        padding: 40px 5%;
    } */
}

@media screen and (max-width: 1024px) {
    .responses-inner {
        padding: 0 30px;
    }
    .submit-story {
        padding: 0 60px;
    }
}

@media screen and (max-device-width: 1024px) and (orientation: landscape) {
    h1 {
        font-size: 28px;
    }
    .home-inner {
        max-width: 500px;
    }
    .main-label {
        font-size: 45px;
    }
    .button {
        font-size: 38px;
    }
    .button img {
        width: 34px;
    }
}

@media screen and (max-device-width: 768px) and (orientation: portrait) {
    .main-label,
    .main-input,
    .phrase-item {
        font-size: 40px;
    }
    .phrase-item {
        line-height: 2.4;
    }
    .button {
        font-size: 35px;
    }
    .button img {
        width: 34px;
    }
    .app-footer {
        justify-content: center;
    }
    .home-inner {
        max-width: 71%;
    }
    .responses-inner {
        text-align: left;
    }
    .responses-toggle {
        display: none;
    }
}

@media screen and (max-device-width: 835px) and (orientation: landscape) {
    .app-name {
        font-size: 13px;
    }
    .header-inner {
        margin-top: 30px;
    }
    .app-menu {
        bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .main-label-arrow {
        top: 10px;
        width: 35px;
        height: 35px;
    }
    .story-form {
        width: 100%;
    }
    .stories {
        height: auto;
        min-height: 100vh;
    }
    .single-response {
        font-size: 18px;
    }
    .responses-list {
        padding-top: 100px;
    }
    .responses-list {
        overflow-y: auto;
    }
    .responses-inner {
        height: 100%;
        margin-bottom: 50px;
    }
    .mobile-menu {
        display: block;
    }
    .app-name {
        margin-bottom: 60px;
    }
    .for-desktop {
        display: none;
    }
    .for-mobile {
        display: block;
    }
    .phrase-toggle {
        display: block;
    }
    .mobile-menu-icon {
        display: block;
    }
    .main-label-toggle {
        position: fixed;
        right: 30px;
        top: 30px;
    }
    .header-inner {
        justify-content: space-between;
        margin-top: 30px;
        padding: 0 30px;
        width: max-content;
        z-index: 99999999;
    }
    .app-menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        background-color: #212325;
        opacity: 0;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms ease-in-out;
        ul {
            flex-direction: column;
        }
    }
    body.show-menu .app-menu {
        opacity: 1;
        z-index: 999999;
    }
    .menu-link {
        font-size: 18px;
        margin: 25px 0;
    }
}

@media screen and (max-width: 640px) {
    .home-inner {
        max-width: 100%;
        padding: 30px;
    }
    .menu-link {
        font-size: 16px;
    }
    .form-group-flex {
        flex-direction: column;
    }
    .success-cta h1 {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .success-headings {
        margin-bottom: 0;
    }
    .success-wrapper .inactive-overlay {
        background: rgba(0, 0, 0, 0.82);
    }
    .success-action-button {
        font-size: 28px;
    }
    .success-arrow,
    .share-channels img {
        width: 30px;
    }
    .share-channels {
        margin-top: 35px;
    }
    .response-owner {
        padding-top: 10px;
    }
    .responses-map {
        position: fixed;
        left: 0;
        top: 0;
        transform: none;
        width: auto;
    }
    .stories {
        height: auto;
        min-height: 100vh;
    }
    .button {
        font-size: 28px;
        img {
            width: 20px;
            margin-left: 12px;
        }
    }
    .main-input {
        padding: 10px 0;
    }
    .submit-button {
        right: 0;
        left: 0;
        bottom: -100px;
        margin: auto;
    }
    .main-label-arrow {
        width: 28px;
        height: 28px;
    }
    li.active .phrase-dots {
        display: none;
    }
    .form-footer {
        padding-top: 10px;
        flex-direction: column;
    }
    a[class^="share-network"] {
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    body {
        font-size: 16px;
    }
    .about-project-inner {
        margin-top: 50px;
        position: relative;
        left: 0;
        top: 0;
        transform: none;
    }
    .about-inner {
        max-width: 100%;
    }
    .phrase-item {
        color: #ffffff;
        margin: 10px 0;
    }
    .phrase-dots {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    h1,
    .success-action-button {
        font-size: 26px;
        line-height: 1.6;
    }
    .app-name {
        margin-bottom: 30px;
        font-size: 14px;
    }
    .header-inner {
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 20px;
    }
    .main-label {
        margin-bottom: 25px;
    }
    .submit-story {
        padding-left: 25px;
        padding-right: 25px;
    }
    .header {
        padding: 30px;
    }
    .about-project-inner {
        padding: 25px;
    }
    .form-group-block,
    .form-input {
        width: 100%;
    }
    .form-input {
        margin-right: 0;
        font-size: 20px;
        padding: 9px 0;
        width: 100%;
    }
    .form-group-block label {
        display: none;
    }
    .flex {
        margin-bottom: 50px;
    }
    .main-label {
        margin-top: 0;
        font-size: 29px;
        align-items: center;
        justify-content: space-between;
        display: flex;
    }
    .main-label-arrow {
        order: 1;
        position: relative;
        top: 0;
    }
    .main-input,
    .phrase-item {
        font-size: 27px;
    }
    .form-group-block {
        margin-bottom: 50px;
    }
    .app-footer {
        font-size: 14px;
        bottom: 22px;
    }
    .app-footer small {
        color: #b1b1b1;
    }
    .menu-link {
        font-size: 14px;
    }
    .app-copyright-bar {
        text-align: center;
        left: 0;
        padding: 0;
    }
}

/* @media screen and (max-width: 414px) { 
}*/

@media screen and (max-width: 320px) {
    h1 {
        font-size: 26px;
    }
    p {
        font-size: 15px;
        line-height: 1.5;
    }
    .main-label,
    .main-input,
    .phrase-item {
        font-size: 23px;
    }
    .mobile-menu {
        width: 28px;
    }
    .form-input {
        padding: 5px 0;
    }
    /* .form-group-block label {
        font-size: 12px;
    } */
    .phrase-dropdown {
        margin-top: 0;
    }
    .single-response {
        font-size: 16px;
    }
    .success-wrapper h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 280px) {
    .submit-story {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* #### Mobile Phones Portrait #### */

@media screen and (max-device-width: 480px) and (orientation: portrait) {
}

/* #### Mobile Phones Landscape #### */

@media screen and (max-device-width: 640px) and (orientation: landscape) {
    /* some CSS here */
}

.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom {
    background: #0c2533 !important;
}
